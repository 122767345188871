html, body {
	padding:			0px;
	margin:				0px;
	width:				100%;
	height:				100%;
}

input {
	-webkit-appearance: none;
	-moz-appearance: 	none;

	&[type="submit"] {
		cursor: 		pointer;
	}
}

a {
	text-decoration: 	none;
}

img {
	border:				none;
	outline: 			none;
}