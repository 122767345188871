@mixin fontFace ($family, $src) {
	@font-face {
		font-family:			$family;
		src:					url('#{$src}.eot'); // IE9 compat
		src:					url('#{$src}.eot?#iefix') format('embedded-opentype'), // IE8 and below
								url('#{$src}.woff') format('woff'), // standards
								url('#{$src}.ttf') format('truetype'), // Safari, Android, iOS
								url('#{$src}.svg##{$family}') format('svg'); // legacy iOS
	}
}

@mixin borderRadius ($radius) {
	-webkit-border-radius:		$radius;
	-moz-border-radius:			$radius;
	border-radius:				$radius;
}

@mixin fontStyle ($size, $font-family, $color) {
	font-size:					$size;
	font-family:				$font-family;
	color:						$color;
}

@mixin transitionStyle ($ms) {
	-webkit-transition:			$ms;
	-moz-transition:			$ms;
	-o-transition:				$ms;
	transition:					$ms;
}

@mixin boxSizing ($value) {
	box-sizing: 				$value;
	-moz-box-sizing: 			$value;
	-webkit-box-sizing: 		$value;
}

@mixin backgroundStyle ($position, $size, $repeat) {
	background-position: 		$position;
	background-size: 			$size;
	background-repeat: 			$repeat;
}

@mixin transformStyle ($deg) {
	-webkit-transform: rotate($deg);
	-moz-transform: rotate($deg);
	-o-transform: rotate($deg);
	-ms-transform: rotate($deg);
}